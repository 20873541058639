<template>
  <page-skeleton theme="light">
    <section-half-image
        hero
        arrow
        scroll-to="custom-arrow"
        image-path="https://ik.imagekit.io/isotronic/products/custom/custom_ZSv3huPC3uS_T-qfAD3xh.png?updatedAt=1635265532013"
        img-background="reverse-gradient"
        :alt="$t('products.custom.hero.alt')"
        small-photo
    >
      <template #header>
        {{$t('products.custom.hero.header')}}
      </template>
      <template #content>
        {{$t('products.custom.hero.text')}}
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/custom/custom_vials_myQwHbeAe1c_i6ipOMUnMxZ.jpg?updatedAt=1635265581461"
        background="light"
        photo-right-align
        id="custom-arrow"
        :alt="$t('products.custom.section1.alt')"
        small-photo
        round-radius
    >
      <template #header>
        {{ $t('products.custom.section1.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('products.custom.section1.text') }}
        </p>
        <p>
          {{ $t('products.custom.section1.text2') }}
        </p>
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/custom/vial_with_defect_u6jaBKqUrK4_DsLGtQRFAD.jpg?updatedAt=1635265626874"
        :alt="$t('products.custom.section2.alt')"
    >
      <template #header>
        {{ $t('products.custom.section2.header') }}
      </template>
      <template #content>
        <div>
          {{ $t('products.custom.section2.text') }}
        </div>
        <ul class="mt-3">
          <li>
            <i18n path="products.custom.section2.feature1.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.custom.section2.feature1.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.custom.section2.feature2.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.custom.section2.feature2.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.custom.section2.feature3.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.custom.section2.feature3.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.custom.section2.feature4.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.custom.section2.feature4.style') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
        <div class="mt-2">
          {{$t('products.custom.section2.text2')}}
        </div>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/products/custom/small_custom_vials_hx1_tbEKtPv_byRwaBk0KL.jpg?updatedAt=1635265677419"
        :alt="$t('products.custom.fullImg.alt1')"
    />
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/custom/vial_front_of_camera_r47J3jaOdFf_v8UDRZKbOFM.jpg?updatedAt=1635265707225"
        photo-right-align
        button-link="Contact"
        background="light"
        :alt="$t('products.custom.section3.alt')"
    >
      <template #header>
        {{ $t('products.custom.section3.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('products.custom.section3.text') }}
        </p>
        <p>
          {{ $t('products.custom.section3.text2') }}
        </p>
      </template>
      <template #callout>
        {{$t('products.custom.section3.callout')}}
      </template>
    </section-half-image>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage
  },
  metaInfo() {
    return {
      title: this.$t('products.custom.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('products.custom.meta.description')}
      ]
    }
  },
}
</script>
